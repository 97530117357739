import React from 'react'
import { ASSETS_ENDPOINT } from '../../../../../config'

const File = props => {
    return (
        <div className="file">
            <div>
                <h4>{props.name}</h4>
                <p>Uploaded: {new Date(props.timestamp).toString("dd MMM, yyyy")}</p>
                <p><a target="_blank" rel="noopener noreferrer" href={ASSETS_ENDPOINT +"/" + props.file}>Download File</a></p>
            </div>
            <div></div>
        </div>
    )
}

export default File