import React from 'react'
import ArticlesWorker from './articles'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Emitter, { EmitterEvents } from '../../../services/emitter'
import iconEdit from '../../../assets/icons/edit-black.svg'
import iconDelete from '../../../assets/icons/delete-black.svg'
import Pagination from '../../../components/pagination/pagination.component'

class Articles extends React.Component {

    constructor(props) {
        super(props)
        this.articlesWorker = ArticlesWorker(this)
        this.state = this.articlesWorker.getDefaultState()
    }

    componentDidMount() {
        this.articlesWorker.loadArticles()
        Emitter.on(EmitterEvents.SEARCH_TEXT_ENTERED, filter => this.setState({
            ...this.state,
            filter: filter
        }, this.articlesWorker.loadArticles))
    }

    componentWillUnmount() {
        Emitter.off(EmitterEvents.SEARCH_TEXT_ENTERED)
    }

    onDelete = id => this.setState({
        ...this.state,
        articles: this.state.articles.filter(article => article.id !== id)
    })

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.articlesWorker.loadArticles())
    }

    render() {
        return (
            <div className="articles">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <Link className="btn" to="/article/articles/create">New Article</Link>
                    <div className="header">
                        <h4>Articles</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Title</th>
                                <th>Journal</th>
                                <th>Article Type</th>
                                <th>Pages</th>
                                <th>DOI</th>
                                <th>Pub. Date</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                                this.state.articles.map((article, key) => <tr key={key}>
                                    <td>{article.number}</td>
                                    <td>{article.title}</td>
                                    <td>{article.journal.url.toUpperCase()}</td>
                                    <td>{article.articleType.name}</td>
                                    <td>{article.pageStart} - {article.pageEnd}</td>
                                    <td>{article.doi}</td>
                                    <td>{new Date(article.publicationDate).toString("dd MMM, yyyy")}</td>
                                    <td>{new Date(article.updated).toString("dd MMM, yyyy")}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/article/articles/edit/' + article.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.onDelete(article.id)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Articles)