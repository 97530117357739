import React from "react";
import CKEditor from "ckeditor4-react-advanced";

const ArticleForm = (props) => {
  return (
    <form>
      <div className="inputs">
        <div className="row">
          <div className="form-label">
            <label>Journal</label>
          </div>
          <div className="form-input">
            <select
              name="journalId"
              value={props.journalId}
              onChange={props.onChange}
            >
              <option value=""></option>
              {props.journals.map((journal, key) => (
                <option key={key} value={journal.id}>
                  {journal.title}
                </option>
              ))}
            </select>
            <span className="error">{props.errors.journalId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Article Type</label>
          </div>
          <div className="form-input">
            <select
              name="articleTypeId"
              value={props.articleTypeId}
              onChange={props.onChange}
            >
              <option value=""></option>
              {props.articleTypes.map((articleType, key) => (
                <option key={key} value={articleType.id}>
                  {articleType.name}
                </option>
              ))}
            </select>
            <span className="error">{props.errors.articleTypeId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Title</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="title"
              value={props.title}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.title}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Keywords</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="keywords"
              value={props.keywords}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.keywords}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Description</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="description"
              value={props.description}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.description}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Page Start</label>
          </div>
          <div className="form-input">
            <input
              type="number"
              name="pageStart"
              value={props.pageStart}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.pageStart}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Page End</label>
          </div>
          <div className="form-input">
            <input
              type="number"
              name="pageEnd"
              value={props.pageEnd}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.pageEnd}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Publication Date</label>
          </div>
          <div className="form-input">
            <input
              type="date"
              name="publicationDate"
              value={props.publicationDate}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.publicationDate}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Doi</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="doi"
              value={props.doi}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.doi}</span>
          </div>
        </div>
        {props.children}
        <div className="row editor">
          <div className="form-label">
            <label>Content</label>
          </div>
          <div className="form-input">
            <select
              name="contentType"
              value={props.contentType}
              onChange={props.onChange}
            >
              <option value="1">Abstract</option>
              <option value="2">Full Text</option>
            </select>
          </div>
          <div className={props.contentType === '1'? 'show':'hide'}>
          <CKEditor
            data={props.abstract}
            config={{
              width: "100%",
              versionCheck:false
            }}
            onChange={(event) => {
                const data = event.editor.getData()
                props.onChange({
                    target: {
                        name: "abstract",
                        value: data
                    }
                })
            }}
          />
            <span className="error">{props.errors.abstract}</span>
          </div>
          <div className={props.contentType === '2'? 'show':'hide'}>
          <CKEditor
            data={props.fullText}
            config={{
              width: "100%",
              versionCheck:false
            }}
            onChange={(event) => {
                const data = event.editor.getData()
                props.onChange({
                    target: {
                        name: "fullText",
                        value: data
                    }
                })
            }}
          />
            <span className="error">{props.errors.fullText}</span>
          </div>
        </div>
      </div>
      <div className="actions">
        <button type="submit" onClick={props.onSubmit}>
          {props.action}
        </button>
        <button type="reset" onClick={props.onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ArticleForm;
