import axios from 'axios'
import { API_ENDPOINT } from '../../../../../config'
import store from '../../../../../redux/stores/store'
import { StringValidationRule, EmailValidationRule } from "../../../../../data/validation/rules"
import Validation from "../../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        creating: false,
        title: "",
        firstname: "",
        otherNames: "",
        email: "",
        affiliations: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 0, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 50, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.otherNames, (error) => errors.otherNames = error, { min: { value: 2, error: "Too short" }, max: { value: 200, error: "Too long" } })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => errors.email = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.affiliations, (error) => errors.affiliations = error, { min: { value: 0, error: "Too short" }, max: { value: 200, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addAuthor = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true,
        flag: {
            type: "",
            text: ""
        }
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/add-author",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            articleId: instance.props.match.params.id,
            author: {
                title: instance.state.title,
                firstname: instance.state.firstname,
                otherNames: instance.state.otherNames,
                email: instance.state.email,
                affiliations: instance.state.affiliations
            },
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleAddAuthorResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: "Unknown error"
                }
            })
        })
}

const handleAddAuthorResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.props.onAddAuthor(response.data.data.author)
            instance.props.onCancel()
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}


const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addAuthor: () => addAuthor(instance)
    }
}

export default Worker