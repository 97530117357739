import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule, UrlPathValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        loadingGroups: false,
        title: "",
        url: "",
        abbreviation: "",
        ceased: "",
        email: "",
        issn: "",
        doi: "",
        groupId: "",
        coverImage: false,
        errors: {},
        groups: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadGroups = instance => {
    instance.setState({
        ...instance.state,
        loadingGroups: true
    })

    let criteria = {}

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/groups",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadGroupsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadGroupsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingGroups: false,
                groups: response.data.data.groups
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.groupId, (error) => errors.groupId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.abbreviation, (error) => errors.abbreviation = error, { min: { value: 2, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(UrlPathValidationRule, instance.state.url, (error) => errors.url = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.issn, (error) => errors.issn = error, { min: { value: 0, error: "Too short" }, max: { value: 9, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.doi, (error) => errors.doi = error, { min: { value: 0, error: "Too short" }, max: { value: 200, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.ceased, (error) => errors.ceased = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => errors.email = error, { allowNull: true })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createJournal = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journal/create",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            title: instance.state.title,
            url: instance.state.url,
            abbreviation: instance.state.abbreviation,
            groupId: instance.state.groupId,
            doi: instance.state.doi,
            issn: instance.state.issn,
            ceased: instance.state.ceased,
            email: instance.state.email,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleCreateJournalResponse(instance, response))
        .catch(error => alert(error))
}

const handleCreateJournalResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                creating: false,
                title: "",
                url: "",
                abbreviation: "",
                amount: "",
                email: "",
                issn: "",
                doi: "",
                groupId: "",
                flag: {
                    type: "success",
                    text: "Journal Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const CreateJournalWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadGroups: () => loadGroups(instance),
        createJournal: () => createJournal(instance)
    }
}

export default CreateJournalWorker