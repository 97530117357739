import React from 'react'
import './pdf.styles.scss'
import { withRouter } from 'react-router-dom'
import { ASSETS_ENDPOINT } from '../../../../../config'

const Pdf = props => {
    return (
        <div className="file">
            <h4>PDF</h4>
            <p><a target="_blank" rel="noopener noreferrer" href={ASSETS_ENDPOINT + "/" + props.pdf}>Download File</a></p>
        </div>
    )
}

export default withRouter(Pdf)