import React from 'react'
import { Switch, Route } from "react-router-dom"
import Articles from './articles.page'
import CreateArticle from './create/create.page'
import EditArticle from './edit/edit.page'

const ArticleController = () => {
    return (
        <Switch>
            <Route path="/article/articles/edit/:id" component={EditArticle} />
            <Route path="/article/articles/create" component={CreateArticle} />
            <Route exact path="/article/articles" component={Articles} />
            <Route path="/article/articles/:id" component={Articles} />
        </Switch>
    )
}

export default ArticleController