import React from 'react'
import './auth.styles.scss'

const AuthTemplate = (props) => {
    return (
        <div className="auth">
            <div className="bg"></div>
            <header>
                <h1><br/>International Scholars Journals - Admin</h1>
            </header>
            {props.children}
            <footer>
                <ul>
                    <li>
                        <a rel="noopener noreferrer" href="https://internationalscholarsjournals.org" >Main Site</a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href="https://manuscripts.internationalscholarsjournals.org" target="_blank">Manuscript Submission</a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href="https://internationalscholarsjournals.org/terms-conditions" target="_blank">Terms & Conditions</a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href="https://internationalscholarsjournals.org/privacy-policy" target="_blank">Privacy Policy</a>
                    </li>
                </ul>
                <p>© 2024 International Scholars Journals. All rights reserved.</p>
            </footer>
        </div>
    )
}

export default AuthTemplate