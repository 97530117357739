import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        updating: false,
        loadingJournals: false,
        showAcceptDialog: false,
        showRejectDialog: false,
        showAddFile: false,
        title: "",
        status: "",
        journal: "",
        errors: {},
        authors: [],
        files: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadManuscript = instance => {
    instance.setState({
        ...instance.state,
        loadingManuscript: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/submission/admin/manuscript",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            manuscriptNumber: instance.props.match.params.id,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadManuscriptResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadManuscriptResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingManuscript: false,
                id: response.data.data.manuscript.id,
                title: response.data.data.manuscript.title,
                status: response.data.data.manuscript.status,
                journal: response.data.data.manuscript.journal.url.toUpperCase(),
                authors: response.data.data.manuscript.authors,
                files: response.data.data.manuscript.files,
                flag: {
                    type: "",
                    text: ""
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingManuscript: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const updateManuscript = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/manuscripts/submit",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.state.id,
            title: instance.state.title,
            journalId: instance.state.journalId
        }
    }).then(response => handleUpdateManuscriptResponse(instance, response))
        .catch(error => alert(error))
}

const handleUpdateManuscriptResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            alert('Manuscript submitted, our team will contact you')
            instance.props.history.push('/manuscripts')
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const showAcceptDialog = (instance, shouldShow) => {
    instance.setState({
        ...instance.state,
        showAcceptDialog: shouldShow
    })
}

const showRejectDialog = (instance, shouldShow) => {
    instance.setState({
        ...instance.state,
        showRejectDialog: shouldShow
    })
}

const EditWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadingManuscript: () => loadManuscript(instance),
        updateManuscript: () => updateManuscript(instance),
        showAcceptDialog: shouldShow => showAcceptDialog(instance, shouldShow),
        showRejectDialog: shouldShow => showRejectDialog(instance, shouldShow)
    }
}

export default EditWorker