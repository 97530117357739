import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        metrics: [0, 0],

    }
}

const loadMetrics = instance => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/content/newsletter-subscription/metrics",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadMetricsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadMetricsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                metrics: response.data.data.metrics
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(response.data.message)
    }
}

const exportRecipients = instance => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/content/newsletter-subscription/export",
        responseType: 'arraybuffer',
        headers: {
            "Authorization": store.getState().auth.authorization
        }
    }).then(response => handleExportRecipientsResponse(instance, response))
        .catch(error => alert(error))
}

const handleExportRecipientsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    instance.setState({
        ...instance.state,
        loading: false
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Data.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        loadMetrics: () => loadMetrics(instance),
        exportRecipients: () => exportRecipients(instance)
    }
}

export default Service