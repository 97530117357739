import React from 'react'
import JournalsWorker from './journals'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import iconEdit from '../../../assets/icons/edit-black.svg'
import iconDelete from '../../../assets/icons/delete-black.svg'
import Pagination from '../../../components/pagination/pagination.component'
import Checkbox from '../../../components/inputs/checkbox/checkbox.component'
import './journals.styles.scss'

class Journals extends React.Component {

    constructor() {
        super()
        this.journalsWorker = JournalsWorker(this)
        this.state = this.journalsWorker.getDefaultState()
    }

    componentDidMount() {
        this.journalsWorker.loadJournals()
    }

    onDelete = id => this.setState({
        ...this.state,
        journals: this.state.journals.filter(journal => journal.id !== id)
    })

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.journalsWorker.loadJournals())
    }

    onIncludeCeasedChange = () => {
        this.setState({
            ...this.state,
            includeCeased: !this.state.includeCeased,
            currentPage: 1
        }, () => this.journalsWorker.loadJournals())
    }

    render() {
        return (
            <div className="journals">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <div className="card">
                    <div className="btns">
                        <Link className="btn" to="/journal/journals/create">New Journal</Link>
                        <Checkbox name="includeCeased" text="Ceased Only" checked={this.state.includeCeased} onChange={this.onIncludeCeasedChange} />
                    </div>
                    <div className="header">
                        <h4>Journals</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Url</th>
                                <th>Title</th>
                                <th>Group</th>
                                <th>ISSN</th>
                                <th>DOI</th>
                                <th>Updated</th>
                                <th>Menu</th>
                                <th>Articles</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.journals.map((journal, key) => <tr key={key}>
                                    <td>{journal.url.toUpperCase()}</td>
                                    <td>{journal.title}</td>
                                    <td>{journal.group.name}</td>
                                    <td>{journal.issn}</td>
                                    <td>{journal.doi}</td>
                                    <td>{new Date(journal.updated).toString("dd MMM, yyyy")}</td>
                                    <td>
                                        <Link to={"/journal/pages/" + journal.id}>View</Link>
                                    </td>
                                    <td>
                                        <Link to={"/article/articles/" + journal.id}>View</Link>
                                    </td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/journal/journals/edit/' + journal.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.onDelete(journal.id)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Journals)