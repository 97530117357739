import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        journals: [],
        includeCeased: false,
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadJournals = instance => {
    instance.setState({ ...instance.state, loading: true })

    let criteria = {}

    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    criteria.ceased = instance.state.includeCeased? 1: 0

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journals",
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadJournalsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadJournalsResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            journals: response.data.data.journals,
            total: response.data.data.size
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const JournalsWorker = instance => {
    return {
        loadJournals: () => loadJournals(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default JournalsWorker