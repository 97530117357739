import React from 'react'

const JournalForm = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Group</label>
                    </div>
                    <div className="form-input">
                        <select name="groupId" value={props.groupId} onChange={props.onChange}>
                            <option value=""></option>
                            {
                                props.groups.map((group, key) => <option key={key} value={group.id}>{group.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.groupId}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="title" value={props.title} onChange={props.onChange} />
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Abbreviation</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="abbreviation" value={props.abbreviation} onChange={props.onChange} />
                        <span className="error">{props.errors.abbreviation}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Url</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="url" value={props.url} onChange={props.onChange} />
                        <span className="error">{props.errors.url}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Doi</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="doi" value={props.doi} onChange={props.onChange} />
                        <span className="error">{props.errors.doi}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Issn</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="issn" value={props.issn} onChange={props.onChange} />
                        <span className="error">{props.errors.issn}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Ceased</label>
                    </div>
                    <div className="form-input">
                        <select name="ceased" value={props.ceased} onChange={props.onChange}>
                            <option value="-"></option>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select>
                        <span className="error">{props.errors.ceased}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Email</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="email" value={props.email} onChange={props.onChange} />
                        <span className="error">{props.errors.email}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                {
                    props.creating || props.updating ?
                        <button type="submit" onClick={props.onSubmit} disabled={true}>wait...</button> :
                        <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                }
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default JournalForm